import styled from 'styled-components';

const newChatButton = styled.div`
	position: fixed;
	bottom: 20px;
	margin-left: 335px;
	@media screen and (max-width: 991px) {
		margin-left: 240px;
	}
	@media screen and (max-width: 767px) {
		margin-left: inherit;
		right: 20px;
	}
`;

export default newChatButton;
