import styled from 'styled-components';

const InputDropdownWrapper = styled.div`
	position: relative;
	transition: all ease 0.4s;
	> span {
		position: absolute;
		right: 14px;
		top: 17px;
		font-size: 12px;
		cursor: pointer;
		opacity: 0.6;
		transition: ease all 0.4s;
		:hover {
			opacity: 1;
		}
	}
	input {
		padding-right: 40px;
		@media screen and (max-width: 767px) {
			padding-right: 30px;
		}
	}
`;
export default InputDropdownWrapper;
