import styled from 'styled-components';
import * as colors from '../colors';

const WelcomeInfo = styled.p`
	line-height: 33px;
	font-size: 20px;
	padding-right: 150px;
	color: ${colors.BlueBase};
	@media screen and (max-width: 1200px) {
		padding-right: 0px;
	}
`;

export default WelcomeInfo;
