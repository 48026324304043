import styled from 'styled-components';

const ChatBack = styled.button`
	width: 30px;
	height: 100%;
	padding: 0;
	border-radius: 0px;
	border: 0px;
	transition: ease all 0.4s;
	text-align: left;
	background: transparent;
	&:hover, &:focus, &:active; {
		background: transparent;
	}
	span {
		font-size:18px;
	}
`;

export default ChatBack;
