import styled from 'styled-components';

const CardsBody = styled.div`
	display: inline-block;
	width: 100%;
	background: #f5f6f9;
	border-radius: 0 0 4px 4px;
	padding: 16px;
`;

export default CardsBody;
