import styled from 'styled-components';

const HeadingPageNameInfo = styled.div`
	font-size: 14px;
	line-height: 18px;
	color: #585858;
	margin-bottom: 29px;
`;

export default HeadingPageNameInfo;
