import styled from 'styled-components';

const HeadingLogo = styled.div`
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	@media screen and (max-width: 600px) {
		margin-bottom: 20px;
		img {
			width: 100px;
		}
	}
`;

export default HeadingLogo;
