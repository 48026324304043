import styled from 'styled-components';

const DropdownItems = styled.li`
	padding: 15px 20px 15px 20px;
	list-style: none;
	border-bottom: 1px solid #676e8f;
	cursor: pointer;
	transition: ease-in all 0.3s;
	:last-child {
		border: 0px;
	}
`;
export default DropdownItems;
