import styled from 'styled-components';
import { colors } from '../colors';

const IcomingChat = styled.div`
	width: auto;
	max-width: 375px;
	padding: 14px;
	border-radius: 0 14px 14px 14px;
	background: ${colors.IncomingChatbg};
	color: ${colors.White};
	font-size: 14px;
	line-height: 21px;
	margin-top: 6px;
	position: relative;
	${({ chatImage }) =>
		chatImage &&
		`padding: 6px;
        overflow: hidden;
        display: flex;
        img{
			max-width: 100%;
			width: auto;
            border-radius: 0 14px 14px 14px;
		}
		video{
			border-radius: 0 14px 14px 14px;
		}
	`}
	div {
		display: flex;
	}
`;

export default IcomingChat;
