import styled from 'styled-components';
import { colors } from '../colors';

const Line = styled.div`
    width: 100%;
    height: 1px !important;
    margin: 15px 0;
    border: 0 none;
    color: ${colors.Border2};
    background-color: ${colors.Border2};
}
`;

export default Line;
