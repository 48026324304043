import styled from 'styled-components';
import { fontMedium } from '../../assets/styles/typography';
import { colors } from '../colors';

const PopoverContent = styled.div`
	transition: ease-in all 0.3s;
	position: absolute;
	background: ${colors.White};
	border: 1px solid #dfe1e5;
	box-shadow: 0px 2px 8px rgba(15, 37, 95, 0.2);
	border-radius: 4px;
	padding: 20px 15px;
	width: auto;
	z-index: 9;
	color: ${colors.BlueBase};
	font-size: 14px;
	max-width: 200px;
	top: ${(props) => props.top};
	right: ${(props) => props.right};
	left: ${(props) => props.left};
	bottom: ${(props) => props.bottom};
	z-index: 1;
	display: none;
	:before {
		content: '';
		position: absolute;
		top: -10px;
		left: calc(50% - 75px);
		z-index: -1;
		width: 0px;
		height: 0px;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid ${colors.White};
	}
	:after {
		content: '';
		position: absolute;
		top: -12px;
		left: calc(50% - 76px);
		z-index: -2;
		width: 0px;
		height: 0px;
		border-left: 11px solid transparent;
		border-right: 11px solid transparent;
		border-bottom: 11px solid #dfe1e5;
	}
	${({ passwordInfo }) =>
		passwordInfo &&
		`&:after{
			bottom: -12px;
			top: inherit;
			border-top: 11px solid #dfe1e5;
			border-bottom:0px;
		}
		&:before{
			bottom: -10px;
			top: inherit;
			border-top: 10px solid ${colors.White};
			border-bottom:0px;
		}
		@media screen and (max-width: 600px) {
                left:-150px!important;
                :after{
                    left: calc(90% - 10px);
                }
                :before{
                    left: calc(90% - 10px);
                }
            }
		`}
	${({ show }) =>
		show &&
		`display: inline-block;
		`}
	${({ topView }) =>
		topView &&
		`top: inherit;
		bottom: 30px;
        `}
	ul {
		padding: 0 0 0 15px;
		white-space: nowrap;
		font-size: 12px;
		margin: 0;
		list-style: none;
		li {
			padding-bottom: 4px;
			list-style: none;
			position: relative;
			:before {
				content: '';
				width: 5px;
				height: 5px;
				border-radius: 20px;
				background: ${colors.BlueBase};
				position: absolute;
				left: -16px;
				top: 6px;
			}
			span {
				font-family: ${fontMedium.familyName};
			}
		}
	}
`;
export default PopoverContent;
