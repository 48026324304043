import styled from 'styled-components';

const PageContainer = styled.div`
	display: inline-block;
	width: 100%;
	display: flex;
	padding: 0 24px 24px 24px;
	padding: ${(props) => props.padding};
	margin: ${(props) => props.margin};
	overflow: auto;
	overflow: ${(props) => props.Overflow};

	${({ flexColumn }) =>
		flexColumn &&
		`flex-direction: column;
    `};

	${({ AlignCenter }) =>
		AlignCenter &&
		`justify-content: center;
    `};

	${({ PaginationContainer }) =>
		PaginationContainer &&
		`display: inline-block;
    `};

	${({ onlyVerticalScroll }) =>
		onlyVerticalScroll &&
		`overflow-y: auto;
        overflow-x: hidden;
    `};

	${({ onlyHorizontalScroll }) => onlyHorizontalScroll && `overflow-x: auto; overflow-y: hidden; `};

	${({ childFullHeight }) => childFullHeight && `display: inline-grid; `};
`;

export default PageContainer;
