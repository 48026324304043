import styled from 'styled-components';

const timeLineListItems = styled.li`
	transition: all ease 0.4s;
	margin: 0;
	padding: 20px 0 5px;
	list-style: none;
	position: relative;
	&:last-child {
		padding-bottom: 20px;
	}
`;
export default timeLineListItems;
