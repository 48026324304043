import styled from 'styled-components';
import { colors } from '../colors';

const ChatWrapper = styled.div`
	width: 100%;
	padding: 40px 100px;
	min-height: calc(100vh - 222px);
	max-height: calc(100vh - 222px);
	overflow: auto;
	scrollbar-color: ${colors.ScrollbarColor};
	scrollbar-width: thin;
	@media screen and (max-width: 991px) {
		padding: 20px 20px;
	}
	@media screen and (max-width: 768px) {
		min-height: calc(100vh - 201px);
		max-height: calc(100vh - 201px);
	}
`;

export default ChatWrapper;
