import styled from 'styled-components';
import { fontMedium, fontRegular } from '../../assets/styles/typography';
import { colors } from '../colors';

const InputDropdownContent = styled.ul`
	transition: ease-in all 0.3s;
	position: absolute;
	background: ${colors.White};
	border: 1px solid #dfe1e5;
	box-shadow: 0px 2px 8px rgba(15, 37, 95, 0.2);
	border-radius: 8px;
	width: 100%;
	z-index: 9;
	color: ${colors.BlueBase};
	font-size: 14px;
	font-family: ${fontRegular.familyName};
	min-width: 300px;
	margin: 0;
	padding: 0;
	display: none;
	max-height: 300px;
	overflow: auto;
	top: ${(props) => props.top};
	right: ${(props) => props.right};
	left: ${(props) => props.left};
	bottom: ${(props) => props.bottom};
	${({ Show }) =>
		Show &&
		`display:block;
	`}
	h3 {
		padding: 15px 20px 15px 20px;
		font-family: ${fontMedium.familyName};
		font-size: 16px;
		line-height: 19px;
		font-weight: normal;
		position: relative;
		> span {
			position: absolute;
			right: 20px;
			top: 16px;
			color: #46bcff;
		}
	}
	p {
		font-size: 14px;
		line-height: 16px;
	}
	label {
		font-family: ${fontMedium.familyName};
		font-size: 15px;
		margin-bottom: 5px;
		display: block;
	}
	${({ DarkDropdown }) =>
		DarkDropdown &&
		`background: #474F77;
		box-shadow: 0px 2px 8px rgba(15, 37, 95, 0.2);
		color:#EBEEF5;
		h3{
			color:#EBEEF5;
			border-bottom: 1px solid #676e8f;
		}
		label{
			color: #abbae0;
			span {
				color: #46bcff;
			}
		}
		li{
			:hover{
				background: #40486f;
			}
		}
	`}
	${({ LightDropdown }) =>
		LightDropdown &&
		`background: ${colors.White};
		box-shadow: 0px 2px 8px rgba(15, 37, 95, 0.2);
		border: 1px solid #DFE1E5;
		li{
			:hover{
				background: ${colors.GreyEC};
			}
			border-bottom: 1px solid #e5e7e9;
			color: ${colors.Black}
		}
	`}
`;
export default InputDropdownContent;
