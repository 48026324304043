import styled from 'styled-components';
import { colors } from '../colors';

const TableButton = styled.button`
	border: 0px;
	background: transparent !important;
	padding: 0px;
	width: auto !important;
	height: auto !important;
	transition: ease all 0.4s;
	span {
		color: ${colors.BlueBase};
		transition: ease all 0.4s;
	}
	&:hover {
		span {
			color: ${colors.BlueLight1};
		}
	}
	${({ special }) => special && `span{color: ${colors.GreyA2}}`}
	${({ noaction }) =>
		noaction &&
		`pointer-events: none;
	span{
		pointer-events: none;
	}`}
    ${({ onHover }) => onHover && `display: inline-block !important; margin-left: 5px;`}
    ${({ icons }) => icons && `display: inline-block !important; margin-left: 15px;`}
`;
export default TableButton;
