import styled from 'styled-components';
import { colors } from '../colors';

const NoData = styled.div`
	width: 100%;
	min-height: ${(props) => props.MinHeight};
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	line-height: 23px;
	flex-direction: column;
	color: ${colors.BlueBase};
	height: ${(props) => props.Height};

	img {
		margin-bottom: 10px;
	}
`;
export default NoData;
