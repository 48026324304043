import styled from 'styled-components';

const Tooltip = styled.span`
	transition: all ease 0.4s;
	position: relative;
	width: 100%;
	display: inherit;
	line-height: 14px;
	&:hover {
		> span {
			display: inline-block;
		}
	}
`;
export default Tooltip;
