import styled from 'styled-components';

const Bredcrumb = styled.section`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export default Bredcrumb;
