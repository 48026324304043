import styled from 'styled-components';
import CollapseBody from './collapseBody';
import CollapseHeader from './collapseHeader';
import { colors } from '../colors';

const Collapse = styled.section`
	display: inline-block;
	position: relative;
	box-sizing: border-box;
	width: 100%;
	${({ show }) =>
		show &&
		`${CollapseBody}{
			display: block;
		}
		${CollapseHeader}{
			border-bottom: 1px solid ${colors.GreyEC};
			padding-bottom: 15px;
			margin-bottom: 20px;
		}
	`};
`;
export default Collapse;
