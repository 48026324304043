import styled from 'styled-components';

const CollapseBody = styled.div`
	display: inline-block;
	position: relative;
	box-sizing: border-box;
	width: 100%;
	display: none;
`;
export default CollapseBody;
