import styled from 'styled-components';

const SearchIcon = styled.div`
	width: 32px;
	height: 32px;
	position: absolute;
	right: 6px;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	span {
		color: #767676;
		font-size: 15px;
	}
`;
export default SearchIcon;
