import styled from 'styled-components';
import { colors } from '../colors';

const TimeLineList = styled.ul`
	transition: all ease 0.4s;
	margin: 0 0 0 30px;
	padding: 0 0 0 40px;
	list-style: none;
	border-left: 1px solid ${colors.GreyEC};
`;
export default TimeLineList;
