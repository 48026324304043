import styled from 'styled-components';
import { colors } from '../colors';

const AddAvatar = styled.div`
	position: absolute;
	right: 5px;
	bottom: 0;
	transition: all ease 0.4s;
	background: ${colors.Grey};
	border: 2px solid ${colors.White};
	color: ${colors.BlueBase};
	width: 24px;
	height: 24px;
	display: flex;
	border-radius: 100%;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	cursor: pointer;
	input[type='file'] {
		width: 24px;
		height: 24px;
		padding: 0px;
		opacity: 0;
		position: absolute;
		right: 5px;
		bottom: 0;
		cursor: pointer;
	}
	&:hover {
		background: ${colors.BlueLight};
		cursor: pointer;
		color: ${colors.White};
		transition: all ease 0.4s;
	}
`;
export default AddAvatar;
