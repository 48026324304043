import styled from 'styled-components';
import { colors } from '../colors';

const ModalHeader = styled.div`
	position: relative;
	padding: 30px 24px 0;
	display: flex;
	justify-content: space-between;
	padding: ${(props) => props.padding};
	p {
		font-size: 14px;
		line-height: 22px;
		color: #585858;
	}
	h3 {
		font-size: 20px;
	}
	${({ BorderHeader }) =>
		BorderHeader &&
		`border-bottom: 1px solid ${colors.Border2};
		padding-bottom:24px;
	`}

	${({ FlexColumn }) =>
		FlexColumn &&
		`flex-direction:column;
	`}
	${({ insulinHeader }) =>
		insulinHeader &&
		`flex-wrap:wrap;
		align-item:center;
		@media screen and (max-width: 991px) {
			div {
				width:100% !important;
				order:1 !important;
				margin-top:10px !important;
			}
		}
	`}
`;

export default ModalHeader;
