import { snackBarErrorTypes } from '../../helpers/Constants';

const FILE = 'FILE://SNACKBAR';

const generateActionType = (type) => `${FILE}/${type}`;

const SNACKBAR_OPEN = generateActionType('SNACKBAR_OPEN');
const SNACKBAR_CLOSE = generateActionType('SNACKBAR_CLOSE');

export function showSnackbar(message, messageType) {
	return { type: SNACKBAR_OPEN, message, messageType };
}

export function clearSnackbar() {
	return { type: SNACKBAR_CLOSE };
}

const initialState = {
	openSnackbar: false,
	messageType: snackBarErrorTypes.info,
	snackBarMessage: 'Records updated successfully',
};

const snackBarReducer = (state = initialState, action) => {
	switch (action.type) {
		case SNACKBAR_OPEN: {
			const messageType = Object.values(snackBarErrorTypes).includes(action.messageType)
				? action.messageType
				: snackBarErrorTypes.info;

			return {
				...state,
				openSnackbar: true,
				snackBarMessage: action.message || 'Records updated successfully',
				messageType,
			};
		}
		case SNACKBAR_CLOSE:
			return {
				...state,
				openSnackbar: false,
			};
		default:
			return state;
	}
};

export default snackBarReducer;
