import styled from 'styled-components';
import { colors } from '../colors';

const SidebarListItems = styled.li`
	width: 100%;
	min-height: 48px;
	list-style: none;
	flex-direction: column;
	a {
		display: flex;
		align-items: center;
		color: ${colors.White};
		transition: ease-in all 0.4s;
		cursor: pointer;
		margin-bottom: 1px;
		text-decoration: none;
		overflow: hidden;
		span {
			display: inline-block;
			width: 62px;
			text-align: center;
			height: 100%;
			padding: 17px 24px;
			font-size: 17px;
			text-decoration: none;
			color: ${colors.White};
		}
		:hover {
			background: ${colors.BlueLight};
			color: ${colors.White};
			span {
				color: ${colors.White};
			}
		}
	}
	> div {
		width: 100%;
		diaplay: flex;
		align-items: center;
	}
	${({ active }) => active && `background: ${colors.BlueLight};`}
	${({ SubMenu }) =>
		SubMenu &&
		`
    display: flex;
        align-items: center;
        color: ${colors.White};
        transition: ease-in all 0.4s;
        cursor: pointer;
        margin-bottom: 1px;
        text-decoration:none;
        overflow: hidden;
        span{
            display: inline-block;
            width: 62px;
            text-align: center;
            height: 100%;
            padding: 17px 24px;
			text-decoration:none;
			font-size:17px;
            color: ${colors.White};
        }
        :hover{
            background: ${colors.BlueLight};
            color: ${colors.White};
            span{
                color: ${colors.White};
            }
        }
    `}
`;

export default SidebarListItems;
