import styled from 'styled-components';
import { colors } from '../colors';

const PopoverTitle = styled.span`
	position: relative;
	cursor: default;
	:hover {
		+ div {
			width: auto;
			height: auto;
			display: block;
		}
	}
	${({ passwordInfo }) =>
		passwordInfo &&
		`color: ${colors.BlueBase};
        font-size: 16px;
        padding-left:5px;
        position: relative;
        top: 3px;
    `}
`;
export default PopoverTitle;
