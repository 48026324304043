import styled from 'styled-components';

const TabsContent = styled.div`
	width: 100%;
	padding: 24px;
	display: none;
	padding-top: ${(props) => props.paddingTop};
	padding: ${(props) => props.padding};
	${({ active }) =>
		active &&
		`
	display: inline-block;
	`}
	${({ scrollable }) =>
		scrollable &&
		`
		max-height:60vh;
		overflow: auto;
	`}
`;

export default TabsContent;
