import styled, { keyframes } from 'styled-components';
import { fontRegular } from '../../assets/styles/typography';
import { colors } from '../colors';

const fadeIn = keyframes`
  from {
		right: 0;
		opacity: 0;
	}
	to {
		right: 30px;
		opacity: 1;
	}
`;
const fadeOut = keyframes`
 from {
		right: 30px;
		opacity: 1;
	}
	to {
		right: 0;
		opacity: 0;
	}
`;

const handleColorType = (type) => {
	switch (type) {
		case 'Success':
			return `border:1px solid #5AD17B;
                    border-left:5px solid #5AD17B;
                    > span{
                        color: #5AD17B;
                        font-size: 20px;
                    }
                `;
		case 'Error':
			return `border:1px solid #FD4848;
                    border-left:5px solid #FD4848;
                    > span{
                        color: #FD4848;
                        font-size: 18px;
                    }
                `;
		case 'Warning':
			return `border:1px solid #F0C531;
                    border-left:5px solid #F0C531;
                    > span{
                        color: #F0C531;
                        font-size: 20px;
                    }
	`;
		default:
			return `border:1px solid #536DFE;
                    border-left:5px solid #536DFE;
                    > span{
                        color: #536DFE;
                        font-size: 20px;
                    }
	`;
	}
};

const Toast = styled.div`
	transition: all ease 0.4s;
	background: ${colors.White};
	color: ${colors.Black};
	font-size: 13px;
	padding: 22px 22px 22px 25px;
	min-width: 300px;
	max-width: 400px;
	height: auto;
	display: none;
	align-items: center;
	border-radius: 4px;
	position: absolute;
	z-index: 999999;
	box-shadow: 0px 0px 10px #b1aeae;
	transition: ease-in all 0.4s;
	// -webkit-animation: ${fadeIn} 0.5s, ${fadeOut} 0.5s 2.5s;
	// animation: ${fadeIn} 0.5s, ${fadeOut} 0.5s 2.5s;
	right: ${(props) => props.Right};
	left: ${(props) => props.Left};
	top: ${(props) => props.Top};
	bottom: ${(props) => props.Bottom};
	${({ show }) => show && `display: inline-flex;`}
	${({ iconToast }) => iconToast && `padding-left:75px;`}
	@media screen and (max-width: 500px) {
		min-width: auto;
		max-width: calc(100% - 40px);
	}

	> span {
		position: absolute;
		font-size: 33px;
		left: 0;
		top: 0;
		height: 100%;
		display: flex;
		width: 75px;
		justify-content: center;
		align-items: center;
	}
	p {
		font-size: 14px;
		color: ${colors.White};
		font-family: ${fontRegular.familyName};
		line-height: 20px;
	}
	button {
		background: transparent !important;
		padding: 0px;
		width: 20px;
		font-size: 8px;
		height: 20px;
		position: absolute;
		right: 5px;
		top: 5px;
		color: ${colors.White};
		opacity: 0.6;
		&:hover {
			opacity: 1;
		}
	}
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		li {
			padding: 0;
			list-style: none;
			font-size: 14px;
			color: ${colors.White};
			font-family: ${fontRegular.familyName};
			line-height: 20px;
			padding: 0 0 5px 0;
			&:last-child {
				padding: 0px;
			}
		}
	}
	${({ messageType }) => messageType && handleColorType(messageType)};

	${({ WarningToast }) =>
		WarningToast &&
		`border:1px solid #F0C531;
		border-left:5px solid #F0C531;
		> span{
			color: #F0C531;
			font-size: 20px;
		}
	`};
	${({ SuccessToast }) =>
		SuccessToast &&
		`border:1px solid #5AD17B;
		border-left:5px solid #5AD17B;
		> span{
			color: #5AD17B;
			font-size: 20px;
		}
	`};
	${({ ErrorToast }) =>
		ErrorToast &&
		`border:1px solid #FD4848;
		border-left:5px solid #FD4848;
		> span{
			color: #FD4848;
			font-size: 18px;
		}
	`};
	${({ ToastBorder }) =>
		ToastBorder &&
		`button{
			color: #84829F;
		}
		p{
			color: #1A2E59;
		}
		ul{
			li{
				color: #1A2E59;
			}
		}
		> span{
			width: 60px;
		}
		padding: 22px 30px 22px 60px;
		box-shadow: 0px 9px 10px rgba(0, 0, 0, 0.15);
	`};
`;

export default Toast;
