import styled from 'styled-components';
import { colors } from '../colors';

const NotificationStatus = styled.span`
	transition: all ease 0.4s;
	position: relative;
	padding-left: 15px;
	white-space: nowrap;
	&:before {
		width: 10px;
		height: 10px;
		border-radius: 20px;
		content: '';
		position: absolute;
		left: 0;
		top: 3px;
	}
	${({ Moderate }) =>
		Moderate &&
		`color:${colors.Yellow};
		&:before{
		background: ${colors.Yellow};
	}`}
	${({ Severe }) =>
		Severe &&
		`color:${colors.Red1};
	&:before{
	background: ${colors.Red1};
}`}
${({ Mild }) => Mild && `color:${colors.Green1}; &:before{ background: ${colors.Green1}; }`}
`;
export default NotificationStatus;
