import React from 'react';

import { NoData } from '../stories';

import NoDataSVG from '../stories/assets/images/no-data-available.svg';

function NoDataPlaceHolder(props) {
	const { title = 'No data available', height = '300px', miHeight = '200px' } = props;
	return (
		<NoData Height={height} MinHeight={miHeight}>
			<img src={NoDataSVG} alt={title} />
			{title}
		</NoData>
	);
}

export default NoDataPlaceHolder;
