import { POST } from '../../service/HttpService';

import { showSnackbar } from '../../components/Snackbar/snackbar.store';
import { saveToLocalStorage, saveUserDetails } from '../Login/login.store';

import { extractMessageFrom } from '../../helpers/Utilities';
import { APP_TOKEN, REFRESH_TOKEN, snackBarErrorTypes } from '../../helpers/Constants';

const FILE = 'FILE://RESET_PASSWORD';

const generateActionType = (type) => `${FILE}/${type}`;

// Action Types
const CHANGE_PASSWORD = generateActionType('CHANGE_PASSWORD');
const SUCCESSFULLY_CHANGED_PASSWORD = generateActionType('SUCCESSFULLY_CHANGED_PASSWORD');
const FAILED_TO_CHANGE_PASSWORD = generateActionType('FAILED_TO_CHANGE_PASSWORD');
const RESET_PASSWORD_FAILURE_ERROR = generateActionType('RESET_PASSWORD_FAILURE_ERROR');
const PASSWORD_RESET_SUCCESSFULLY = generateActionType('PASSWORD_RESET_SUCCESSFULLY');

// Actions
function initiatePasswordChange() {
	return { type: CHANGE_PASSWORD };
}

function successInChangingPassword() {
	return { type: SUCCESSFULLY_CHANGED_PASSWORD };
}

function failureInChangingPassword(msg) {
	return { type: FAILED_TO_CHANGE_PASSWORD, msg };
}

export function clearError() {
	return { type: RESET_PASSWORD_FAILURE_ERROR };
}

// Thunks
export function changePassword(credentials) {
	const changeConfig = { data: credentials, url: 'accounts/change-password' };
	return async function (dispatch) {
		dispatch(initiatePasswordChange());
		try {
			const { data = {} } = await POST(changeConfig);
			const { accessToken, refreshToken = 'NO REFRESH TOKEN YET' } = data;
			saveToLocalStorage({ [APP_TOKEN]: accessToken, [REFRESH_TOKEN]: refreshToken });
			dispatch(saveUserDetails({ ...data }));
			dispatch(successInChangingPassword());
		} catch (error) {
			// const { response = {} } = error;
			// const { data = {} } = response;
			dispatch(failureInChangingPassword());
		}
	};
}

export function resetPassword(credentials) {
	const resetConfig = { data: credentials, url: 'accounts/reset-password' };
	return async function (dispatch) {
		dispatch(initiatePasswordChange());
		try {
			await POST(resetConfig);
			dispatch(successInChangingPassword());
			dispatch(
				showSnackbar('Password has been reset. Please login again.', snackBarErrorTypes.success)
			);
			dispatch({ type: PASSWORD_RESET_SUCCESSFULLY });
		} catch (error) {
			dispatch(showSnackbar(extractMessageFrom(error), snackBarErrorTypes.error));
			dispatch(failureInChangingPassword('Session has expired. Please try again.'));
		}
	};
}

// Reducer
const initialState = {
	resettingPassword: false,
	hasTokenInUrl: false,
	passwordResetSuccess: false,
	error: {
		hasError: false,
		message: '',
	},
};

function resetReducer(state = initialState, action) {
	switch (action.type) {
		case PASSWORD_RESET_SUCCESSFULLY:
			return {
				...initialState,
				hasTokenInUrl: false,
				resettingPassword: false,
			};
		case CHANGE_PASSWORD:
			return {
				...initialState,
				hasTokenInUrl: false,
				resettingPassword: true,
			};
		case SUCCESSFULLY_CHANGED_PASSWORD:
			return {
				...initialState,
				passwordResetSuccess: true,
			};
		case FAILED_TO_CHANGE_PASSWORD:
			return {
				...initialState,
				error: {
					hasError: true,
					message: action.msg || 'Session timed out. Please login again.',
				},
			};
		case RESET_PASSWORD_FAILURE_ERROR:
			return {
				...state,
				error: {
					hasError: false,
					message: '',
				},
			};
		default:
			return state;
	}
}

export default resetReducer;
