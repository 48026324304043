import styled from 'styled-components';
import { colors } from '../colors';

const TableResponsive = styled.div`
	display: block;
	width: 100%;
	overflow-x: ${({ overflowNone }) => (overflowNone ? 'none' : 'auto')};
	-webkit-overflow-scrolling: touch;
	${({ TableListHeight }) =>
		TableListHeight &&
		`    min-height: calc(100vh - 180px);
	`}
	${({ BorderLR }) =>
		BorderLR &&
		`    border-left: 1px solid ${colors.Border2};
			border-right: 1px solid ${colors.Border2};
			border-radius:6px;
	`}
	${({ TableLScroll }) =>
		TableLScroll &&
		`min-height: calc(100vh - 450px);
		max-height: calc(100vh - 450px);
		overflow: auto;

		@media screen and (max-width: 767px){
			min-height: auto;
			max-height: inherit;
		}
	`}
	${({ TableLScrollLG }) =>
		TableLScrollLG &&
		`min-height: calc(100vh - 180px);
		max-height: calc(100vh - 180px);
		overflow: auto;

		@media screen and (max-width: 767px){
			min-height: auto;
			max-height: 400px;
		}
`}
	${({ PaginationTable }) =>
		PaginationTable &&
		`min-height: calc(100vh - 220px);
		max-height: calc(100vh - 220px);
		overflow: auto;

		@media screen and (max-width: 767px){
			min-height: auto;
			max-height: 400px;
		}
`}
`;
export default TableResponsive;
