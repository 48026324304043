import BoldWoff from '../fonts/inter-bold-webfont.woff2';
import LightBetaWoff from '../fonts/inter-light-beta-webfont.woff2';
import MediumWoff from '../fonts/inter-medium-webfont.woff2';
import RegularWoff from '../fonts/inter-regular-webfont.woff2';

export const fontRegular = {
	familyName: 'interregular',
	path: RegularWoff,
	locale: 'inter-regular-webfont.woff2',
	format: 'woff2',
};
export const fontMedium = {
	familyName: 'intermedium',
	path: MediumWoff,
	locale: 'inter-medium-webfont.woff2',
	format: 'woff2',
};
export const fontBold = {
	familyName: 'interbold',
	path: BoldWoff,
	locale: 'inter-bold-webfont.woff2',
	format: 'woff2',
};
export const fontLight = {
	familyName: 'interlight_beta',
	path: LightBetaWoff,
	locale: 'inter-light-beta-webfont.woff2',
	format: 'woff2',
};
