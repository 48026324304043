import styled from 'styled-components';

const DropdownListSepertor = styled.div`
	height: 1px;
	width: 100%;
	background: #e5e7e9;
	margin: 4px 0;
`;

export default DropdownListSepertor;
