import styled from 'styled-components';

const TooltipContent = styled.span`
	transition: all ease 0.4s;
	background: #0d224f;
	border-radius: 8px;
	padding: 10px 14px;
	color: #ffffff;
	width: auto;
	max-width: 300px;
	position: absolute;
	font-size: 13px;
	display: none;
	z-index: 99;
	line-height: 19px;
	top: ${(props) => props.Top};
	left: ${(props) => props.Left};
	bottom: ${(props) => props.Bottom};
	right: ${(props) => props.Right};
	&:before {
		width: 0;
		height: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: 8px solid #0d224f;
		content: '';
		position: absolute;
		top: -8px;
		left: calc(50% - 8px);
	}
`;
export default TooltipContent;
