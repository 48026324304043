import styled from 'styled-components';
import { colors } from '../colors';

const SidebarSubMenu = styled.ul`
	width: 100%;
	margin: 0px;
	padding: 0px;
	list-style: none;
	white-space: nowrap;
	background: ${colors.BlueDarkX};
	transition: ease-in all 0.4s;
	height: 0px;
	overflow: hidden;
	${({ show }) => show && ` Height: auto; padding:9px 0; transition: ease-in all 0.4s; `}
`;

export default SidebarSubMenu;
