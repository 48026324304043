import styled from 'styled-components';
import { fontMedium } from '../../assets/styles/typography';
import { colors } from '../colors';
import FormLabel from '../formGroup/formLabel';

const Input = styled.input`
	display: inline-block;
	width: 100%;
	background: ${colors.White};
	border: 1px solid ${colors.BorderColorTB};
	border-radius: 6px;
	height: 48px;
	padding: 16px;
	font-size: 14px;
	transition: ease-in all 0.4s;
	box-sizing: border-box;
	font-family: ${fontMedium.familyName};
	color: ${colors.BlueBase};
	&[type='search']::-ms-clear {
		display: none;
		width: 0;
		height: 0;
	}
	&[type='search']::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}
	&[type='search']::-webkit-search-decoration,
	&[type='search']::-webkit-search-cancel-button,
	&[type='search']::-webkit-search-results-button,
	&[type='search']::-webkit-search-results-decoration {
		display: none;
	}
	@media screen and (max-width: 767px) {
		padding: 16px 10px;
	}

	border-color: ${(props) => props.error && colors.Red} !important;
	border-color: ${(props) => props.warning && colors.Yellow} !important;

	background: ${(props) => props.disabled && colors.Grey100};
	opacity: ${(props) => props.disabled && '.5'};
	pointer-events: ${(props) => props.disabled && 'none'};

	&:focus {
		outline: none;
		border: 1px solid ${colors.BlueBase};
	}
	${({ floatingInput }) =>
		floatingInput &&
		`&:focus + ${FormLabel}, &:not(:placeholder-shown) ~ ${FormLabel}{
            position: absolute;
            top: -6px;
            left: 12px;
            opacity: 1;
            font-size: 12px;
            line-height: 14px;
            background: ${colors.White};
            width: auto;
            padding:0 5px;
            color: ${colors.BlueBase};
          }
          &:focus, &:not(:placeholder-shown){
              border-color: ${colors.BlueBase};
		  }		

	`}
	${({ InputActive }) =>
		InputActive &&
		`+ ${FormLabel} {
            position: absolute;
            top: -6px;
            left: 12px;
            opacity: 1;
            font-size: 12px;
            line-height: 14px;
            background: ${colors.White};
            width: auto;
            padding:0 5px;
			color: ${colors.BlueBase};			
		  }
		  border-color: ${colors.BlueBase};
    `}
`;

export default Input;
