import styled from 'styled-components';
// eslint-disable-next-line import/no-cycle
import { ModalBody, ModalHeader, ModalFooter } from '.';
import { colors } from '../colors';

const ModalInnerWrapper = styled.div`
	transition: ease-in all 0.2s;
	box-sizing: border-box;
	width: 500px;
	background: ${colors.White};
	border-radius: 14px;
	display: flex;
	flex-direction: column;
	@media screen and (max-width: 500px) {
		width: 90%;
	}
	${({ ModalLG }) =>
		ModalLG &&
		`width:950px;
		@media screen and (max-width: 991px) {
			width: 90%;
		}
	`};
	${({ ModalSM }) =>
		ModalSM &&
		`width:350px;
		@media screen and (max-width: 991px) {
			width: 90%;
		}
    `};
	${({ box }) =>
		box &&
		`width:350px;
		@media screen and (max-width: 480px) {
			width: 90%;
		}
    `};
	${({ roasterModal }) =>
		roasterModal &&
		`width: 0px;
		position: absolute;
		right: 0px;
		left: initial;
		top: 0;
		margin: 0!important;
		margin-top: 0!important;
		margin-bottom: 0!important;
		height:100vh;
		max-height:100vh;
		border-radius: 8px 8px 8px 8px !important;
		${ModalBody}{
			flex:auto;
			padding:0 24px 0;
			overflow: auto;
		}
		${ModalHeader}{
			padding:24px;
			border-bottom: 0.5px solid ${colors.GreyE5};;
			border-radius:8px 8px 0px 0px !important;
		}
		${ModalFooter}{
			padding:24px;
			border-top: 0.5px solid ${colors.GreyE5};;
		}
	`}
	${({ autoWidth }) =>
		autoWidth &&
		`width:auto;
		@media screen and (max-width: 480px) {
			width: 90%;
		}
	`}
	${({ InsulinDosagePopup }) =>
		InsulinDosagePopup &&
		`width:900px;
		@media screen and (max-width: 960px) {
			width: 90%;
			.PageScroll{
				overflow:auto;
				.PageScrollList{
					width:800px;
				}
			}
		}
	`}
`;

export default ModalInnerWrapper;
