import styled from 'styled-components';
import { colors } from '../colors';

const DropdownMenu = styled.div`
	transition: ease-in all 0.2s;
	border: 1px solid gray;
	border-top: none;
	opacity: ${(props) => (props.open ? '1' : '0')};
	position: absolute;
	background: ${colors.White};
	border: 1px solid #dfe1e5;
	box-sizing: border-box;
	box-shadow: 0px 2px 8px rgba(15, 37, 95, 0.2);
	border-radius: 4px;
	padding: 5px 0;
	width: auto;
	z-index: 9;
	white-space: nowrap;
	right: 0;
	display: ${(props) => (props.open ? 'block' : 'none')};
	right: ${(props) => props.right};
	left: ${(props) => props.left};
	top: ${(props) => props.top};
	height: ${(props) => props.height};
	overflow: ${(props) => props.overFlow};
	bottom: ${(props) => props.bottom};
	${({ align }) => align && align === 'right' && `right:'0';`}
`;

export default DropdownMenu;
