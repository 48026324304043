import styled from 'styled-components';
import { colors } from '../colors';

const RadioButton = styled.div`
	position: relative;
	width: ${(props) => props.width && props.width};
	&::before {
		content: '';
		border-radius: 100%;
		border: 1px solid ${colors.BorderColorTB};
		background: ${colors.White};
		width: 20px;
		height: 20px;
		position: absolute;
		top: 0;
		box-sizing: border-box;
		pointer-events: none;
		z-index: 0;
	}
	span {
		background: ${colors.BlueLight};
		width: 0;
		height: 0;
		border-radius: 100%;
		position: absolute;
		top: 10px;
		left: 10px;
		transform: translate(-50%, -50%);
		transition: width 0.2s ease-in, height 0.2s ease-in;
		pointer-events: none;
		z-index: 1;
		&::before {
			content: '';
			opacity: 0;
			width: 20px;
			position: absolute;
			height: 20px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border: 1px solid ${colors.BlueLight};
			border-radius: 100%;
		}
	}
	input {
		opacity: 0;
		height: 20px;
		width: 20px;
		cursor: pointer;
		&:checked ~ span {
			width: 8px;
			height: 8px;
			&::before {
				opacity: 1;
				border: 1px solid ${colors.BlueLight};
			}
		}
		&:disabled {
			opacity: 0.5;
			pointer-events: none;
			~ label {
				opacity: 0.5;
			}
		}
	}
	label {
		position: relative;
		top: -5px;
		left: 8px;
	}
`;

export default RadioButton;
