import styled from 'styled-components';

const SidebarList = styled.ul`    
    width: 100%;
    margin: 0px;
    padding: 0px;
    list-style: none;
    white-space: nowrap;
}
`;

export default SidebarList;
