import styled from 'styled-components';
import { colors } from '../colors';

const ValidationMessage = styled.span`
	font-size: 12px;
	font-weight: 300;
	color: ${colors.Red};
	font-weight: 500;
	box-sizing: border-box;
	display: inline-block;
	padding-top: 5px;
	${({ warning }) =>
		warning &&
		`color: ${colors.Yellow};
    `}
`;

export default ValidationMessage;
