import styled from 'styled-components';
import { fontMedium } from '../../assets/styles/typography';
import { colors } from '../colors';

const DropdownIcons = styled.div`
	transition: ease-in all 0.4s;
	padding: 0;
	align-items: center;
	border: 1px solid transparent;
	width: 57px;
	height: 57px;
	border-radius: 4px;
	color: ${colors.BlueBase};
	font-family: ${fontMedium.familyName};
	position: relative;
	float: left;
	display: flex;
	align-items: center;
	justify-content: center;
	:hover {
		background: ${colors.GreyEC};
		cursor: pointer;
	}
	span {
		font-size: 40px;
	}
	${({ iconSmall }) =>
		iconSmall &&
		`	span {
			font-size: 32px;
		}
	`}
`;

export default DropdownIcons;
