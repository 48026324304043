import styled from 'styled-components';
import { colors } from '../colors';

const Header = styled.div`
    display: inline-block;
    width: 100%;
    height: 62px;
    background: ${colors.White};    
    box-shadow: 0px -1px 31px rgba(0, 0, 0, 0.06);
    padding: 15px 24px 15px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 999;
    top:0;
    @media screen and (max-width: 767px) {
		padding-left: 62px;
	}
}
`;

export default Header;
