import styled from 'styled-components';

const PageHeader = styled.section`
	width: 100%;
	display: flex;
	align-items: center;
	padding: 24px;
	${({ chatHeader }) =>
		chatHeader &&
		`padding:24px 15px 15px 24px;
		@media screen and (max-width: 767px) {
			button{
				+ div{
					right:0;
				}
			}
		}
	`};
	${({ advancedFilter }) =>
		advancedFilter &&
		`padding:10px !important;
	`};
	${({ Responsive }) =>
		Responsive &&
		`@media screen and (max-width: 767px) {
		flex-direction: column;
		padding: 15px 24px;
	}
`};
`;

export default PageHeader;
