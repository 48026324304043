import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Toast, Button, Icon } from '../../stories';

import { clearSnackbar } from './snackbar.store';
import { snackBarErrorTypes } from '../../helpers/Constants';

function SnackBarToast() {
	let timeout = null;
	const snackbar = useRef();
	const dispatch = useDispatch();
	const {
		snackBarMessage,
		openSnackbar,
		messageType = 'info',
	} = useSelector((state) => state.SnackBarStore);

	function closeSnackBar() {
		dispatch(clearSnackbar());
	}

	useEffect(() => {
		if (openSnackbar) {
			// eslint-disable-next-line
			timeout = setTimeout(function () {
				closeSnackBar();
			}, 3000);
		}
		return () => {
			clearTimeout(timeout);
		};
	}, [openSnackbar]);

	const handleMessageType = (messageType) => {
		switch (messageType) {
			case snackBarErrorTypes.success:
				return <Icon iconName="icon-icon-tick-single" />;
			case snackBarErrorTypes.error:
				return <Icon iconName="icon-close" />;
			case snackBarErrorTypes.warning:
				return <Icon iconName="icon-warning" />;
			default:
				return <Icon iconName="icon-info-error" />;
		}
	};

	if (openSnackbar)
		return (
			<Toast
				iconToast
				Top="20px"
				ToastBorder
				Right="20px"
				ref={snackbar}
				show={openSnackbar}
				messageType={messageType}
			>
				{handleMessageType(messageType)}
				{snackBarMessage}
				<Button onClick={() => closeSnackBar()}>
					<Icon iconName="icon-close" />
				</Button>
			</Toast>
		);

	return null;
}

export default SnackBarToast;
