import styled from 'styled-components';
import { colors } from '../colors';

const TabsNav = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	border-bottom: 1px solid ${colors.Border2};
	margin: ${(props) => props.margin};
	${({ EqualLinks }) =>
		EqualLinks &&
		`li{
			width: 100%;
		}
	`}
	@media screen and (max-width: 600px) {
		display: block;
		margin: 0px;
	}

	.responsiveChat {
		@media screen and (max-width: 600px) {
			span {
				width: 100%;
				margin-top: 20px;
			}
		}
	}
`;

export default TabsNav;
