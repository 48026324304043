import styled from 'styled-components';
import { fontMedium } from '../../assets/styles/typography';
import { colors } from '../colors';

const BoldText = styled.span`
	font-family: ${fontMedium.familyName};
	display: initial;
	${({ bulletAfter }) =>
		bulletAfter &&
		`
	position: relative;
	padding-right: 15px;
	color: ${colors.BlueBase};
	
	&:after{
		content: '';
		position: absolute;
		right: 3px;
		width: 4px;
		height: 4px;
		border-radius: 20px;
		background: ${colors.BlueBase};
		top: 7px;		
	}
	`}
`;

export default BoldText;
