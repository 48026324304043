import styled from 'styled-components';
import { colors } from '../colors';

const Checkbox = styled.div`
    display: inline-block;
    margin-left: ${(props) => props.marginLeft};
    > input {
    opacity: 0;
    width:0px;
    height:0px;
    }
    > input + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 14px;
    padding-top: 2px;    
    &:before {
        content: '';
        position: absolute;
        left:0;
        top: 1px;
        width: 17px;
        height: 17px;
        border: 1px solid ${colors.BorderColorTB};
        background: ${colors.White};
        border-radius: 3px;
    }
    &:after {
        content: '\\e925';
        position: absolute;
        top: 4.4px;
        left: 4px;
        font-size: 10px;
        font-family: 'iconFont' !important;
        color:${colors.White};
        transition: all .2s;
    }
    }
    > input:not(:checked) + label {
        &:after {
        opacity: 0;
        transform: scale(0);
        }
    }

    > input:checked + label {
        &:after {
            opacity: 1;
            transform: scale(1);
        }
        &:before{
            background: ${colors.BlueLight};
            border: 1px solid ${colors.BlueLight};
        }
    }
    > input:disabled + label {
        pointer-events: none;
        opacity:.5;
        &:after {
            opacity: 0;
            pointer-events: none;
        }
        &:before{
            background: ${colors.Grey100};
            opacity:.5;
            pointer-events: none;
        }
    }
    > input:disabled{
        pointer-events: none;
    }
    > input:checked:focus + label, input:not(:checked):focus + label {
    &:before {
        border: 1px solid ${colors.Border1};;
        background: ${colors.white};
    }
 }
}
`;

export default Checkbox;
