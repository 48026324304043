import styled from 'styled-components';
import { colors } from '../colors';

const HambergerMenu = styled.div`
    width: 100%;
    height:62px;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: ${colors.White};
    position: relative;
    background: ${colors.BlueBase};
    transition: ease-in all 0.4s;
    span{
        display: inline-block;
        width: 62px;
        text-align: center;
        height: 100%;
        padding: 15px 23px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: ease-in all 0.4s;
        &:hover{
            background: ${colors.BlueDark};
        }
        @media screen and (max-width: 767px) {
            color: ${colors.BlueDark};
            background: ${colors.White};
            &:hover{
                background: ${colors.White};
            }
        }
    }
}
`;

export default HambergerMenu;
