import styled from 'styled-components';
import { colors } from '../colors';

const modalFooter = styled.div`
	padding: 0px 24px 30px 24px;
	display: flex;
	padding: ${(props) => props.padding};
	padding-top: ${(props) => props.paddingTop};
	justify-content: flex-end;
	button {
		+ button {
			margin-left: 15px;
		}
	}
	${({ BorderFooter }) =>
		BorderFooter &&
		`border-top: 1px solid ${colors.Border2};
		padding-top:24px;
	`}
`;

export default modalFooter;
