export const getProfileDetails = (state) => {
	const { userDetails = {} } = state;
	return { ...userDetails };
};

export const getClinicalNotes = (state) => {
	const { clinicalNotesAlerts = {} } = state;
	return { ...clinicalNotesAlerts };
};

export const getNotifications = (state) => {
	const { clinicalNotifications = {} } = state;
	return { ...clinicalNotifications };
};

export const getClinicPatients = (state) => {
	const { clinicPatients: { loading, data } = {} } = state;
	return { loading, data };
};

export const getLatestStatistics = (state) => {
	const { clinicPatients: { latestStatistics = {} } = {} } = state;
	return { ...latestStatistics };
};

export const getWebNotifications = (state) => {
	const { userNotifications = {} } = state;
	return { ...userNotifications };
};
