const routeProps = {
	dietDetails: {
		innerWrapper: {
			paddingBottom: '70px',
		},
	},
	patientManagement: {
		innerWrapper: {
			fixedFooterBlockSmall: true,
		},
	},
};

export default routeProps;
