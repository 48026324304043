import styled from 'styled-components';

const SidebarHamberger = styled.div`    
    width: 62px;
    height:62px;
    transition: ease-in all 0.4s;   
}
`;

export default SidebarHamberger;
