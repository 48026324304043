import styled from 'styled-components';
import { colors } from '../colors';

const EnlargeImage = styled.div`
	width: 100%;
	height: 100%;
	background: ${colors.Black};
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	align-items: center;
	justify-content: center;
	display: none;
	overflow: auto;
	transition: ease-n all 0.4s;
	${({ show }) =>
		show &&
		`display: flex;
	`}
	span {
		position: absolute;
		right: 10px;
		top: 10px;
		color: ${colors.White};
		cursor: pointer;
		padding: 8px;
		border-radius: 30px;
		background: ${colors.Black};
		font-size: 12px;
	}
`;

export default EnlargeImage;
