import styled from 'styled-components';

const ResponsivePreview = styled.div`
	display: block;
	width: 375px;
	height: auto;
	border: 1px solid #e5e7e9;
	padding: 20px;
    margin: auto;
    background-color: ${(props) => props.bgColor};
    background-image: url(${(props) => props.bgImg});
    background-repeat: no-repeat;
    background-size: 375px;
}
`;

export default ResponsivePreview;
