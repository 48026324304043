import styled from 'styled-components';
import { colors } from '../colors';

const Avatar = styled.div`
	position: relative;
	transition: all ease 0.4s;
	img {
		width: 100%;
		height: 100%;
	}
	${({ editavatar }) => editavatar && `position: absolute;  top: -35px; left: calc(50% - 61px);`}
	${({ small }) => small && `width: 36px; height: 36px;`}
    ${({ medium }) => medium && `width: 40px; height: 40px;`}
    ${({ large }) => large && `width: 52px; height: 52px;`}
    ${({ xlarge }) => xlarge && `width: 112px; height: 112px;`}
   ${({ semiLarge }) => semiLarge && `width: 64px; height: 64px;`}
	${({ activityStatus }) =>
		activityStatus &&
		`border: 2px solid ${colors.BlueLight}; 
        img { border: 2px solid ${colors.White};}
    `}
	${({ avatarWithBadge }) =>
		avatarWithBadge &&
		`position:relative;
		width:50px;
		height:50px;
        span{
			position: absolute;
			right:-3px;
			bottom:-4px;
			img{
				float:right;
				width:25px;
				height:25px;
			}
        }
	`}
	${({ rounded }) =>
		rounded &&
		`border-radius:100%;
        img{
            width: 100%;
            height: 100%;
            border-radius:100%;
        }
    `}
`;
export default Avatar;
