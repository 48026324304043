import styled from 'styled-components';

const SidebarClose = styled.div`    
    width: 62;
    height:62px;
    transition: ease-in all 0.4s;   
    position: absolute;
    right:0px;
    top: 0px; 
}
`;

export default SidebarClose;
