import IconType1 from '../../../../stories/assets/images/icons/Clinical-Documents.svg';
import IconType2 from '../../../../stories/assets/images/icons/card.svg';
import IconType3 from '../../../../stories/assets/images/icons/alert.svg';
import IconType4 from '../../../../stories/assets/images/icons/AlertIcon.svg';

import HashTags from '../../../../helpers/Constants';

const type3Holder = ['CLINICAL_NOTE'];
const type2Holder = ['PATIENT_PROFILE_UPDATE', 'MARKED_SPECIAL', 'COACH_ASSIGNED'];
const type1Holder = ['CLINICAL_NOTIFICATION'];

function imageMapper(type) {
	if (type3Holder.includes(type)) return IconType3;

	if (type2Holder.includes(type)) return IconType2;

	if (type1Holder.includes(type)) return IconType1;

	return IconType4;
}

const Type1 = ['PATIENT_PROFILE_UPDATE', 'PATIENT_ASSIGNED'];
const Type2 = ['DOCTOR_PROFILE_UPDATE'];
const Type3 = ['COACH_PROFILE_UPDATE'];
const Type4 = ['CLINIC_ADMIN_PROFILE_UPDATE'];
const Type5 = ['ABNORMALITY', 'CLINICAL_DOC', 'VITAL'];

export function linkResolver({ type, patientId, doctorId, coachId, clinicAdminId }) {
	if (Type1.includes(type))
		return {
			goto: `/patientvault?pat_id=${patientId}${HashTags.patientVault.details}`,
		};
	if (Type2.includes(type))
		return {
			goto: `/view/page/${doctorId}`,
		};
	if (Type3.includes(type))
		return {
			goto: `/coach-management/${coachId}`,
		};
	if (Type4.includes(type))
		return {
			goto: `/clinic-management/${clinicAdminId}`,
		};
	if (Type5.includes(type))
		return {
			goto: `/patientvault?pat_id=${patientId}${HashTags.patientVault.health}`,
		};

	return {
		goto: ``,
	};
}

export default imageMapper;
