import React from 'react';

import { Row, Col } from '../../stories';

import './shimmer.css';

export function ImageHolder({ size = {} }) {
	return <div className="card-holder-image animate" style={size} />;
}

export function LineHolder({ classNames = [] }) {
	return <div className={`card-holder-lines animate ${classNames.join(' ')}`} />;
}

export function SingleLine(height = '20px', lines = 1) {
	return [...Array(lines).keys()].map((l) => (
		<div key={l} className="card-holder-lines-single animate" style={{ height }} />
	));
}

export function TableShimmer({ classNames = [], lines = 4 }) {
	const list = [...Array(lines).keys()].map((l) => (
		<div key={l} className={`card-holder-lines-thick animate ${classNames.join(' ')}`} />
	));

	return (
		<div className="card-holder-table">
			<div className="card-holder-table-header">
				<div className={`card-holder-lines animate ${classNames.join(' ')}`} />
			</div>
			{list}
		</div>
	);
}

function ThreeLineHolder() {
	return (
		<div className="card-line-view">
			<LineHolder classNames={['w90']} />
			<LineHolder classNames={['w80', 'mTop6rem']} />
			<LineHolder classNames={['w100', 'mTop6rem']} />
		</div>
	);
}

function ChatShimmer({ needImagePlaceHolder }) {
	return (
		<div className="card-holder">
			{needImagePlaceHolder && <ImageHolder />}
			<ThreeLineHolder />
		</div>
	);
}

function ShimmerPlaceHolder({
	count = 3,
	needImagePlaceHolder = true,
	rowProps = { DashboardList: true },
}) {
	const row = [];
	let i = 0;

	while (i < count) {
		row.push(i);
		i += 1;
	}

	return (
		<>
			{row.map((item) => (
				<Row {...rowProps} key={item}>
					<Col>
						<ChatShimmer needImagePlaceHolder={needImagePlaceHolder} />
					</Col>
				</Row>
			))}
		</>
	);
}

export default ShimmerPlaceHolder;
