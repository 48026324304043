import styled from 'styled-components';
import { colors } from '../colors';

const OnlineStatus = styled.span`
    width: 14px;
    height: 14px;
    border-radius: 100%;
    position: absolute;
    right:0;
    top:0;
    z-index: 1;
    background: ${colors.White};
    :before{
        position: absolute;
        z-index: 2;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        content:'';
        right: 2px;
        top: 2px;
    }
    ${({ Active }) => Active && `&:before{ background: #2EC973;}`}
    ${({ Away }) => Away && `&:before{ background: #FFBB00;} `}
    ${({ Invisible }) => Invisible && `&:before{ background: #ffffff;  border: 1px solid #cecece}`}
    ${({ Offline }) => Offline && `display: none;`}
}
`;
export default OnlineStatus;
