import styled from 'styled-components';

import Image01 from '../../assets/images/chatBegin.png';

const ChatBeginImage = styled.div`
    width: 374px;
    height: 329px;
    background: url(${Image01}) no-repeat;}
`;

export default ChatBeginImage;
