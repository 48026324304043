import styled from 'styled-components';

const LoginRight = styled.div`
	display: inline-block;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	padding-left: 75px;
	padding-top: 65px;
	@media screen and (max-width: 991px) {
		display: none;
	}
	@media screen and (min-width: 1024px) and (max-width: 1365px) {
		padding-top: 0;
		padding-left: 50px;
	}
`;

export default LoginRight;
