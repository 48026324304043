import { lazy } from 'react';

const Login = lazy(() => import('../pages/Login'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/ChangePassword'));

export default [
	{
		exact: true,
		name: 'Login',
		path: '/',
		component: Login,
	},
	{
		exact: true,
		name: 'ForgotPassword',
		path: '/forgot-password',
		component: ForgotPassword,
	},
	{
		exact: true,
		name: 'ResetPassword',
		path: '/reset-password',
		component: ResetPassword,
	},
];
