import styled from 'styled-components';

const NetworkBar = styled.div`
	position: absolute;
	width: 320px;
	height: 52px;
	min-width: 320px;
	width: 100%;
	bottom: 12px;
	background: #0f255f;
	border-radius: 8px;
	color: #ffffff;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	z-index: 9999;
	text-align: center;
	display: ${({ open }) => (open ? 'flex' : 'none')};
	@media screen and (min-width: 480px) {
		right: 16px;
		max-width: 320px;
	}
`;

export default NetworkBar;
